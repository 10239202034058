export default defineEventHandler((event) => {    
    // const res = event.node.res;
    // const max = 31536000/3; // 1 year == 31536000
    // const minCache = 5 * 60; // 5 mins, hr == 60 * 60
    // const url = event.node.req.url;
    // if (url) {
    //     //const maxage = url.match(/(.+)\.(jpg|jpeg|gif|png|ico|svg)/) ? max : minCache;
    //     const maxage = url.match(/(.+)\.(jpg|jpeg|gif|css|png|js|ico|svg|mjs)/) ? max : minCache;
    //     //console.log('Cache: ' + url + ' for ' + maxage);
    //     res.setHeader('Cache-Control', `max-age=${maxage} s-maxage=${maxage}`);
    // }
})